import { TwitterShareButton } from 'react-share';

import LinkedInIcon from '@content/images/social/linkedin-share-icon.png';
import XIcon from '@content/images/social/x-logo-round-black.png';

const ShareThis = ({ title, summary }) => {
    const url = window.location.href;

    return (
        <div className="flex-row align-center social-icons-container">
            <p>Share this</p>
            <a
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
                target="_blank"
                rel="noreferrer"
            >
                <img className="icon" alt="Share on LinkedIn" src={LinkedInIcon} />
            </a>
            <TwitterShareButton title={title} url={url}>
                <img className="icon" alt="Share on X" src={XIcon} />
            </TwitterShareButton>
        </div>
    );
};

export default ShareThis;
