export const metaData = {
    home: {
        title: 'The Cross-Sector Battery Systems Innovation Network',
        description: `Innovate UK's flagship Innovation Networks programme brings together some of the best minds from across the UK in areas of innovation, development and new technologies.`,
    },
    about: {
        title: 'About Us',
        description: null,
    },
    cso: {
        title: 'Cross Sectoral Opportunities',
        description: null,
    },
    news: {
        title: 'News',
        description: null,
    },
    events: {
        title: 'Events',
        description: null,
    },
    resources: {
        title: 'Resources',
        description: null,
    },
    websiteCaseStudies: {
        title: 'Case Studies',
        description: null,
    },
    networkingHub: {
        title: 'Networking Hub',
        description: null,
    },
    networkingHubProblems: {
        title: 'Problems | Networking Hub',
        description: null,
    },
    networkingHubSolutions: {
        title: 'Solutions | Networking Hub',
        description: null,
    },
    landscapeMap: {
        title: 'Battery Systems Landscape Map',
        description: null,
    },
    contact: {
        title: 'Contact Us',
        description: null,
    },
    login: {
        title: 'Login',
        description: null,
    },
    register: {
        title: 'Register',
        description: null,
    },
    forgotPassword: {
        title: 'Forgot Password',
        description: null,
    },
    resetPassword: {
        title: 'Reset Password',
        description: null,
    },
    privacyPolicy: {
        title: 'Privacy Policy',
        description: null,
    },
    dashboard: {
        title: 'Dashboard',
        description: null,
    },
    profile: {
        title: 'My Profile',
        description: null,
    },
    connectionRequests: {
        title: 'Connection Requests',
        description: null,
    },
    myProblemCaseStudies: {
        title: 'My Problem Case Studies',
        description: null,
    },
    mySolutionCaseStudies: {
        title: 'My Solution Case Studies',
        description: null,
    },
    privacy: {
        title: 'Privacy Policy',
        description: null,
    },
    pageNotFound: {
        title: 'Page Not Found',
        description: null,
    },
};
