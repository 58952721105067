import { useState } from 'react';

import { crossSectoralOpportunitiesData } from '@constants/crossSectoralOpportunities';
import { metaData } from 'src/constants/meta';

import CSOBannerImage from '@content/images/banners/cross-sectoral-banner.png';
import LastImage from '@content/images/cross-sectoral-opportunities/last-image.png';
import WindfarmsImage from '@content/images/cross-sectoral-opportunities/windfarms.jpg';

import ActionButton from 'lib/src/components/button/ActionButton';
import Meta from '@components/meta/Meta';
import Banner from '@components/banner/Banner';
import OpportunityModal from '../modal/OpportunityModal';

const CrossSectoralOpportunities = () => {
    const [showModal, setShowModal] = useState(false);
    const [opportunityToShow, setOpportunityToShow] = useState({});

    return (
        <>
            <Meta data={metaData.cso} />

            <div className="container">
                <div
                    className={!showModal ? `hidden` : 'cso-modal-overlay'}
                    onClick={() => closeModal(false)}
                />

                <Banner title="Cross-Sectoral Opportunities" image={CSOBannerImage} />
                <div className="flex-row flex-wrap flex-column-lm">
                    {crossSectoralOpportunitiesData.map((opportunity, index) => {
                        const { id, subheading, preview, headingColour, bodyColour, image } =
                            opportunity;

                        return (
                            <div
                                key={id}
                                className={`cso-card-container flex-column justify-between flex-4 ${bodyColour}`}
                            >
                                {showModal && opportunityToShow.id === id && (
                                    <OpportunityModal
                                        opportunity={opportunityToShow}
                                        index={index}
                                        closeModal={closeModal}
                                    />
                                )}
                                <div className={`cso-subheading ${headingColour}`}>
                                    <h3>{subheading}</h3>
                                </div>
                                <div className="cso-body">
                                    <div
                                        className="wysiwyg limited"
                                        dangerouslySetInnerHTML={{
                                            __html: preview,
                                        }}
                                    />

                                    <ActionButton
                                        color="purple"
                                        extraClasses="cso-button"
                                        onClick={() => handleModalClick(opportunity)}
                                    >
                                        Read more
                                    </ActionButton>
                                </div>

                                <div className="cso-image">
                                    <img src={image} alt={subheading} className="image-fit" />
                                </div>
                            </div>
                        );
                    })}
                    <div className="cso-card-container no-action flex-4">
                        <img
                            className="image-fit"
                            src={LastImage}
                            alt="Woman stares into distance"
                        />
                    </div>
                </div>
            </div>

            <div className="page-bottom-image">
                <img className="image-fit" src={WindfarmsImage} alt="Windfarms in the rising sun" />
            </div>
        </>
    );

    function handleModalClick(opportinity) {
        setOpportunityToShow(opportinity);
        setShowModal(true);
    }

    function closeModal() {
        setShowModal(false);
    }
};

export default CrossSectoralOpportunities;
